var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.experienceData
    ? _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          !_vm.isLoading
            ? _c("template", { slot: "header" }, [
                _c("h2", { staticClass: "dialog-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.experience
                          ? _vm.singular + " bewerken"
                          : _vm.singular + " toevoegen"
                      ) +
                      " "
                  )
                ])
              ])
            : _vm._e(),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "250px"
                    }
                  })
                : _vm._e(),
              !_vm.isLoading
                ? [
                    _c(
                      "v-form",
                      {
                        staticClass: "addEntryForm",
                        model: {
                          value: _vm.valid,
                          callback: function($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid"
                        }
                      },
                      [
                        _c(
                          "v-layout",
                          { attrs: { xs12: "", wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "pt-0",
                                  attrs: {
                                    label: "Organisatie",
                                    placeholder: "Organisatie",
                                    rules: _vm.organizationRules,
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.experienceData.title,
                                    callback: function($$v) {
                                      _vm.$set(_vm.experienceData, "title", $$v)
                                    },
                                    expression: "experienceData.title"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "pt-0",
                                  attrs: {
                                    label: _vm.typeLabel,
                                    placeholder: _vm.typeLabel
                                  },
                                  model: {
                                    value: _vm.experienceData.experience,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.experienceData,
                                        "experience",
                                        $$v
                                      )
                                    },
                                    expression: "experienceData.experience"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.isSchool
                              ? [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          staticClass: "mt-0",
                                          attrs: {
                                            row: "",
                                            label: "Opleiding afgerond"
                                          },
                                          model: {
                                            value: _vm.experienceData.completed,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.experienceData,
                                                "completed",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "experienceData.completed"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-radio",
                                            {
                                              attrs: {
                                                value: true,
                                                color: "#a09b1b"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "label" },
                                                  slot: "label"
                                                },
                                                [_vm._v("Ja")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-radio",
                                            {
                                              attrs: {
                                                value: false,
                                                color: "#a09b1b"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "label" },
                                                  slot: "label"
                                                },
                                                [_vm._v("Nee")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            !_vm.isSchool
                              ? [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-textarea", {
                                        staticClass: "pt-0",
                                        attrs: {
                                          label: "Werkzaamheden",
                                          placeholder: "Werkzaamheden"
                                        },
                                        model: {
                                          value: _vm.experienceData.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.experienceData,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "experienceData.description"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            _c(
                              "v-flex",
                              { attrs: { xs6: "", "mt-3": "" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "close-on-content-click": false,
                                      "max-width": "290",
                                      "nudge-bottom": "32"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "input input-date pt-0",
                                                    attrs: {
                                                      placeholder:
                                                        "Kies een datum",
                                                      label: "Vanaf",
                                                      clearable: "",
                                                      "error-messages":
                                                        _vm.dateFrom
                                                          .errorMessage
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.dateFrom
                                                          .formattedDate,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.dateFrom,
                                                          "formattedDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "dateFrom.formattedDate"
                                                    }
                                                  },
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1321714266
                                    )
                                  },
                                  [
                                    _c("v-date-picker", {
                                      ref: "picker",
                                      attrs: {
                                        locale: "nl-nl",
                                        color: "#837f16",
                                        "first-day-of-week": "1"
                                      },
                                      model: {
                                        value: _vm.dateFrom.selectedDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.dateFrom,
                                            "selectedDate",
                                            $$v
                                          )
                                        },
                                        expression: "dateFrom.selectedDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs6: "", "mt-3": "" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "close-on-content-click": false,
                                      "max-width": "290",
                                      "nudge-bottom": "32"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "input input-date pt-0",
                                                    attrs: {
                                                      placeholder:
                                                        "Kies een datum",
                                                      label: "t/m",
                                                      clearable: "",
                                                      "error-messages":
                                                        _vm.dateTill
                                                          .errorMessage
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.dateTill
                                                          .formattedDate,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.dateTill,
                                                          "formattedDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "dateTill.formattedDate"
                                                    }
                                                  },
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2740915730
                                    )
                                  },
                                  [
                                    _c("v-date-picker", {
                                      ref: "picker",
                                      attrs: {
                                        locale: "nl-nl",
                                        color: "#837f16",
                                        "first-day-of-week": "1"
                                      },
                                      model: {
                                        value: _vm.dateTill.selectedDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.dateTill,
                                            "selectedDate",
                                            $$v
                                          )
                                        },
                                        expression: "dateTill.selectedDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    { attrs: { "justify-end": "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { shrink: "" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "fadedText link mr-3",
                            on: { click: _vm.close }
                          },
                          [_vm._v("Annuleren")]
                        )
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "MiButton",
                            {
                              staticClass: "float--right",
                              attrs: {
                                disabled: !_vm.valid,
                                color: "secondary"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.save($event)
                                }
                              }
                            },
                            [_vm._v("Opslaan")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }