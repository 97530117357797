var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _vm.user && !_vm.$store.state.isServiceOrganization
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "", "text-xs-right": "" } },
                    [
                      !_vm.hasOpenSubmission &&
                      _vm.$store.state.Auth.has_expert_register
                        ? _c(
                            "MiButton",
                            {
                              staticClass: "mx-1",
                              attrs: {
                                color: "primary",
                                small: "true",
                                icon: "add"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.openSubmissionDialog($event)
                                }
                              }
                            },
                            [_vm._v(" Aanvraag aanmaken ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.showFilters
                    ? _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "", "align-end": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c("ReportExpertFilter", {
                                attrs: {
                                  dmz: _vm.organization,
                                  cssClass: "with-border",
                                  placeholder: "Gebruikers"
                                },
                                model: {
                                  value: _vm.tableFilters.user,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tableFilters, "user", $$v)
                                  },
                                  expression: "tableFilters.user"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c("ExpertSubmissionStatusFilter", {
                                attrs: {
                                  cssClass: "with-border",
                                  placeholder: "Status"
                                },
                                model: {
                                  value: _vm.tableFilters.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tableFilters, "status", $$v)
                                  },
                                  expression: "tableFilters.status"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c("ReportExpertFilter", {
                                attrs: {
                                  dmz: _vm.organization,
                                  cssClass: "with-border",
                                  placeholder: "Aanvrager"
                                },
                                model: {
                                  value: _vm.tableFilters.submitted_by,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tableFilters,
                                      "submitted_by",
                                      $$v
                                    )
                                  },
                                  expression: "tableFilters.submitted_by"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c("ReportExpertFilter", {
                                attrs: {
                                  cssClass: "with-border",
                                  placeholder: "Controleur"
                                },
                                model: {
                                  value: _vm.tableFilters.verified_by,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tableFilters,
                                      "verified_by",
                                      $$v
                                    )
                                  },
                                  expression: "tableFilters.verified_by"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("DataTable", {
                    ref: "registerSubmissionDatatable",
                    attrs: {
                      options: _vm.tableOptions,
                      visibility: _vm.visibility
                    },
                    on: { "data-update": _vm.refreshDatatable }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isCreatingSubmission
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700", persistent: true },
              model: {
                value: _vm.isCreatingSubmission,
                callback: function($$v) {
                  _vm.isCreatingSubmission = $$v
                },
                expression: "isCreatingSubmission"
              }
            },
            [
              _c("CreateSubmissionDialog", {
                attrs: {
                  submission: _vm.selectedSubmission,
                  readOnly: _vm.readOnly,
                  user: _vm.user,
                  organization: _vm.organization
                },
                on: { submitted: _vm.refreshDatatable },
                model: {
                  value: _vm.isCreatingSubmission,
                  callback: function($$v) {
                    _vm.isCreatingSubmission = $$v
                  },
                  expression: "isCreatingSubmission"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isRejectingSubmission
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700", persistent: true },
              model: {
                value: _vm.isRejectingSubmission,
                callback: function($$v) {
                  _vm.isRejectingSubmission = $$v
                },
                expression: "isRejectingSubmission"
              }
            },
            [
              _c("RejectSubmissionDialog", {
                attrs: {
                  submission: _vm.selectedSubmission,
                  organization: _vm.organization
                },
                on: { submitted: _vm.refreshDatatable },
                model: {
                  value: _vm.isRejectingSubmission,
                  callback: function($$v) {
                    _vm.isRejectingSubmission = $$v
                  },
                  expression: "isRejectingSubmission"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }