import { cloneDeep } from 'lodash';
import { Skill } from '@/models/Skill';
import { UserLevelItem, UserLevels } from '@/models/User';
import { Component, Prop, Ref } from 'vue-property-decorator';
import { Level, User } from '@/models/User';
import { ExperienceSubmission, ExperienceSubmissionPayload, ExperienceSubmissionStatus } from '@/models/ExperienceSubmission';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import SubmissionExperienceList from '@/views/ExpertRegisterSubmissions/SubmissionExperienceList/SubmissionExperienceList.vue';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import { VerklaringVanOnafhankelijkheidInfo } from '@/support/Info';
import { ExperienceType } from '@/models/Experience';
import { ExperienceSubmissionItem } from '@/models/ExperienceSubmissionItem';
import { rules } from '@/support/ValidationRules';

@Component<CreateSubmissionDialog>({
  components: {
    SubmissionExperienceList,
  },
})
export default class CreateSubmissionDialog extends AbstractMoreInfoDialog {
  @Prop()
  protected submission!: ExperienceSubmission;

  protected submissionData: ExperienceSubmission | null = null;

  @Prop({ default: false })
  protected readOnly!: boolean;

  @Prop()
  protected user!: User;

  @Ref('fileInputAssessment') readonly fileInputAssessment!: HTMLInputElement;

  @Ref('fileInputDeclarationOfExpertise') readonly fileInputDeclarationOfExpertise!: HTMLInputElement;

  @Ref('fileInputDeclarationOfGoodBehaviour') readonly fileInputDeclarationOfGoodBehaviour!: HTMLInputElement;

  protected startedWorkingAt = '';

  protected moreInfo: MoreInfo[] = VerklaringVanOnafhankelijkheidInfo;

  protected submissionExperiences: ExperienceSubmissionItem[] = [];

  protected isRejectingSubmission = false;

  protected fileDeclarationOfExpertise: FileObject = {
    name: '',
    url: '',
    object: null,
  };

  protected fileDeclarationOfGoodBehaviour: FileObject = {
    name: '',
    url: '',
    object: null,
  };

  protected fileAssessment: FileObject = {
    name: '',
    url: '',
    object: null,
  };

  protected userLevels: UserLevelItem[] = UserLevels;

  protected skills: Skill[] = [];

  protected isLoading = false;

  protected rules = rules;

  protected async mounted() {
    await this.getSkills();

    this.submissionData = this.submission ? cloneDeep(this.submission) : new ExperienceSubmission();

    if (this.submissionData.items) {
      this.submissionExperiences = this.submissionData.items;
    }

    if (this.user.is_opnemer) {
      this.submissionData.level = Level.PROCESEXPERT;
    }

    if (this.submissionData.started_working_at) {
      this.startedWorkingAt = this.submissionData.started_working_at;
    }

  }

  protected close() {
    this.$emit('input', false);
  }

  protected addSubmissionExperiences(submissionExperience: ExperienceSubmissionItem) {
    this.submissionExperiences.push(submissionExperience);
  }

  protected updateSubmissionExperiences(submissionExperience: ExperienceSubmissionItem) {
    const index = this.submissionExperiences.findIndex((experience) => experience.id === submissionExperience.id);

    this.$set(this.submissionExperiences, index, submissionExperience);
  }

  protected deleteSubmissionExperiences(submissionExperience: ExperienceSubmissionItem) {
    this.submissionExperiences = this.submissionExperiences.filter((experience) => experience.id !== submissionExperience.id);
  }

  protected async submit() {
    if (! this.submissionData || this.isLoading) {
      return;
    }

    this.isLoading = true;

    await this.submissionData
      .create(this.createPayload)
      .then(async (submission: ExperienceSubmission) => {
        if (this.submissionExperiences.length) {
          const experiencePromises = this.submissionExperiences.map(async (experience) => await this.createSubmissionExperienceItems(experience, submission.id));
          await Promise.all(experiencePromises);
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.isLoading = false;

    this.submitted();
  }

  protected async approve() {
    if (! this.submissionData || this.isLoading) {
      return;
    }

    this.isLoading = true;

    await this.submissionData
      .update(this.updatePayload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.isLoading = false;

    this.submitted();
  }

  protected async createSubmissionExperienceItems(experience: ExperienceSubmissionItem, submissionId: string) {
    const payload = { ...{ submission: submissionId }, ...experience };

    if (payload.completed) {
      payload.completed = 1;
    } else {
      payload.completed = 0;
    }

    await new ExperienceSubmissionItem()
      .create(payload)
      .then((response: any) => {
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected submitted() {
    this.$emit('submitted');
    this.close();
  }

  protected async getSkills() {
    await new Skill()
      .all()
      .then((skills: Skill[]) => {
        this.skills = skills;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected onClickFileSelectDeclarationOfExpertise(): void {
    this.fileInputDeclarationOfExpertise.click();
  }

  protected onClickFileSelectDeclarationOfGoodBehaviour(): void {
    this.fileInputDeclarationOfGoodBehaviour.click();
  }

  protected onClickFileSelectAssessment(): void {
    this.fileInputAssessment.click();
  }

  protected onFileSelectedDeclarationOfExpertise(event: any) {
    const files = event.target.files;
    if (files[0] !== undefined) {
      this.fileDeclarationOfExpertise.name = files[0].name;
      // Check validity of file
      if (this.fileDeclarationOfExpertise.name.lastIndexOf('.') <= 0) {
        return;
      }
      // If valid, continue
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        this.fileDeclarationOfExpertise.url = fr.result;
        this.fileDeclarationOfExpertise.object = files[0];
      });
    } else {
      this.fileDeclarationOfExpertise.name = '';
      this.fileDeclarationOfExpertise.object = null;
      this.fileDeclarationOfExpertise.url = '';
    }
  }

  protected onFileSelectedDeclarationOfGoodBehaviour(event: any) {
    const files = event.target.files;
    if (files[0] !== undefined) {
      this.fileDeclarationOfGoodBehaviour.name = files[0].name;
      // Check validity of file
      if (this.fileDeclarationOfGoodBehaviour.name.lastIndexOf('.') <= 0) {
        return;
      }
      // If valid, continue
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        this.fileDeclarationOfGoodBehaviour.url = fr.result;
        this.fileDeclarationOfGoodBehaviour.object = files[0];
      });
    } else {
      this.fileDeclarationOfGoodBehaviour.name = '';
      this.fileDeclarationOfGoodBehaviour.object = null;
      this.fileDeclarationOfGoodBehaviour.url = '';
    }
  }

  protected onFileSelectedAssessment(event: any) {
    const files = event.target.files;
    if (files[0] !== undefined) {
      this.fileAssessment.name = files[0].name;
      // Check validity of file
      if (this.fileAssessment.name.lastIndexOf('.') <= 0) {
        return;
      }
      // If valid, continue
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        this.fileAssessment.url = fr.result;
        this.fileAssessment.object = files[0];
      });
    } else {
      this.fileAssessment.name = '';
      this.fileAssessment.object = null;
      this.fileAssessment.url = '';
    }
  }

  protected openFileUrl(url: string) {
    if (! url) { return; }
    window.open(url, '_blank');
  }

  protected get createPayload() {
    const payload: ExperienceSubmissionPayload = {
      user: this.user.uuid,
      level: this.submissionData?.level || '',
      skills: this.submissionData?.skills?.map((skill) => skill.id || '') || [],
      submission_notes: this.submissionData?.submission_notes || '',
      available_fte: this.submissionData?.available_fte,
      postcode: this.submissionData?.postcode,
      started_working_at: this.startedWorkingAt,
    };

    if (this.fileDeclarationOfExpertise.object) {
      payload.declaration_of_expertise = this.fileDeclarationOfExpertise.object;
    }

    if (this.fileDeclarationOfGoodBehaviour.object) {
      payload.declaration_of_good_behaviour = this.fileDeclarationOfGoodBehaviour.object;
    }

    if (this.fileAssessment.object) {
      payload.assessment = this.fileAssessment.object;
    }

    return payload;
  }

  protected get updatePayload() {
    const payload: ExperienceSubmissionPayload = {
      agreed_to_declaration_of_independence: this.submissionData?.agreed_to_declaration_of_independence || false,
      status: ExperienceSubmissionStatus.PENDING,
    };

    return payload;
  }

  protected get canEditSubmissionStatus() {
    if (! this.submission) {
      return true;
    }

    return this.submission?.status === ExperienceSubmissionStatus.PENDING && this.$store.state.isServiceOrganization;
  }

  protected get isJunior() {
    return this.submissionData?.level === Level.JUNIOR_DESKUNDIGE;
  }

  protected get canCreateSubmissionItem() {
    return this.submissionData?.level !== Level.PROCESEXPERT && this.submissionData?.level !== Level.SCHADEOPNEMER && ! this.user.is_opnemer;
  }

  protected get isSelf() {
    return this.user.uuid === this.$store.state.Auth.uuid;
  }

  protected get isCheckingExpertStatus(): boolean {
    return this.submission?.status === ExperienceSubmissionStatus.CHECKING_EXPERT;
  }

  protected get isDisabled() {
    if (! this.submissionData) {
      return true;
    }

    let hasDeclarationOfExpertise = true;
    if (this.isJunior) {
      hasDeclarationOfExpertise = !! this.fileDeclarationOfExpertise.object;
    }

    return ! (hasDeclarationOfExpertise) || false;
  }

  protected get Level() {
    return Level;
  }

  protected get ExperienceType() {
    return ExperienceType;
  }
}

export interface FileObject {
  name: string;
  url: string | null | ArrayBuffer;
  object: string | null;
}
