import { formatDate } from '@/support/String';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/models/User';
import { ExperienceSubmission, ExperienceSubmissionStatus, statusColors, statusLabels } from '@/models/ExperienceSubmission';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import CreateSubmissionDialog from '@/views/ExpertRegisterSubmissions/CreateSubmissionDialog/CreateSubmissionDialog.vue';
import RejectSubmissionDialog from '@/views/ExpertRegisterSubmissions/RejectSubmissionDialog/RejectSubmissionDialog.vue';
import ExpertSubmissionStatusFilter from '@/components/filters/expert-submission-status-filter/ExpertSubmissionStatusFilter.vue';

@Component<ExpertRegisterSubmissionsDatatable>({
  components: {
    CreateSubmissionDialog,
    RejectSubmissionDialog,
    ExpertSubmissionStatusFilter,
  },
})
export default class ExpertRegisterSubmissionsDatatable extends Vue {
  @Prop()
  protected user!: User;

  @Prop()
  protected organization!: string;

  @Prop({ default: false })
  protected groupByUser!: boolean;

  @Prop({ default: false })
  protected showFilters!: boolean;

  protected isLoading = true;

  protected readOnly = false;

  protected selectedSubmission: ExperienceSubmission | null = null;

  protected submissions: ExperienceSubmission[] | null = null;

  protected isRejectingSubmission = false;

  protected isCreatingSubmission = false;

  protected tableFilters: TableFilter = {};

  protected experts: User[] = [];

  protected selectedExperts: User[] = [];

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
    search: false,
  }

  public async mounted() {
    this.tableFilters.group_by_user = this.groupByUser;
    if (this.user?.uuid) {
      this.tableFilters.user = this.user.uuid;
      await this.fetchSubmissions();
    }

    this.isLoading = false;
  }

  protected async fetchSubmissions() {
    if (! this.$store.state.Auth.has_expert_register) {
      return;
    }

    const model = this.organization ? new ExperienceSubmission().dmz(this.organization) : new ExperienceSubmission();

    this.submissions = await model
      .filter({
        status: [ExperienceSubmissionStatus.PENDING, ExperienceSubmissionStatus.CHECKING_EXPERT],
        user: this.user?.uuid,
      })
      .all();
  }

  protected openSubmissionDialog() {
    this.selectedSubmission = null;
    this.isCreatingSubmission = true;
    this.readOnly = false;
  }

  protected handleFilterChange() {
    // empty
  }

  protected get isSelf() {
    return this.user?.uuid === this.$store.state.Auth.uuid;
  }

  get tableOptions(): Object {
    const model = this.organization ? new ExperienceSubmission().dmz(this.organization) : new ExperienceSubmission();
    return {
      model: model.include(['user', 'submitter', 'verifier', 'items', 'assessment']).filter(this.dataTableFilters),
      name: {
        singular: 'Deskundigenregister aanvragen',
        plural: 'Deskundigenregister aanvragen',
      },
      headers: [
        {
          text: 'Gebruiker',
          value: 'user',
          action: (experienceSubmission: ExperienceSubmission) => {
            if (this.$store.state.isServiceOrganization) {
              this.$router.push(`/expert-user/${experienceSubmission?.user?.uuid}?organization=${this.organization}&tab=tab-deskundige-register-submissions`);
            } else {
              this.$router.push(`/users/${experienceSubmission?.user?.uuid}?tab=tab-deskundige-register-submissions`);
            }
          },
          transform: (user: User) => user?.name || '',
          visible: () => ! this.user,
          sortable: {
            key: 'name',
            order: 'ASC',
          },
        },
        {
          text: 'Status',
          value: 'status',
          transform: (status: string) => {
            if (! status) {
              return '';
            }
            return `<span class="statusPill statusBackgroundColor--${statusColors[status]}">${statusLabels[status]}</span>`;
          },
          sortable: {
            key: 'status',
            order: 'ASC',
          },
        },
        {
          text: 'Aangemaakt op',
          value: 'created_at',
          transform: (created_at: string) => formatDate(created_at, 'dd-LL-yyyy HH:mm'),
          sortable: {
            key: 'created_at',
            order: 'ASC',
          },
        },
        {
          text: 'Aanvrager',
          value: 'submitter',
          transform: (submitter: User) => submitter?.name || '',
        },
        {
          text: 'Controleur',
          value: 'verifier',
          transform: (verifier: User) => verifier?.name || '',
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: 'create',
          action: (dataTable: DataTable, experienceSubmission: ExperienceSubmission) => {
            this.selectedSubmission = experienceSubmission;
            this.isCreatingSubmission = true;
            this.readOnly = false;
          },
          visible: (dataTable: DataTable, experienceSubmission: ExperienceSubmission) => {
            if (this.isSelf) {
              return false;
            }
            return experienceSubmission.status === ExperienceSubmissionStatus.REJECTED && ! this.$store.state.isServiceOrganization && this.user && ! this.hasOpenSubmission;
          },
          tooltip: 'Opnieuw aanmaken',
        },
        {
          type: 'view',
          label: 'view',
          icon: 'remove_red_eye',
          action: (dataTable: DataTable, experienceSubmission: ExperienceSubmission) => {
            if (this.user) {
              this.selectedSubmission = experienceSubmission;
              this.isCreatingSubmission = true;
              this.readOnly = true;
            } else if (this.$store.state.isServiceOrganization) {
              this.$router.push(`/expert-user/${experienceSubmission?.user?.uuid}?organization=${this.organization}&tab=tab-deskundige-register-submissions`);
            } else {
              this.$router.push(`/users/${experienceSubmission?.user?.uuid}?tab=tab-deskundige-register-submissions`);
            }
          },
          tooltip: 'Bekijken',
        },
      ],
      filters: [],
    };
  }

  private get dataTableFilters() {
    return this.tableFilters;
  }

  protected get registerSubmissionDatatable(): DataTable|null {
    const dataTable = this.$refs.registerSubmissionDatatable;
    if (dataTable) {
      return (dataTable as DataTable);
    }

    return null;
  }

  protected refreshDatatable() {
    this.fetchSubmissions();
    if (this.$refs.registerSubmissionDatatable) {
      (this.$refs.registerSubmissionDatatable as DataTable).refresh();
    }
  }

  protected get hasOpenSubmission() {
    return !! this.submissions?.length;
  }
}

interface TableFilter {
  status?: string | null;
  users?: string[];
  user?: string | null;
  submitted_by?: string | null;
  verified_by?: string | null;
  group_by_user?: boolean;
}
