var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c("template", { slot: "header" }, [
            _vm._v("Deskundigenregister aanvraag formulier")
          ]),
          _c(
            "template",
            { slot: "content" },
            [
              !_vm.submissionData
                ? _c("LoaderCard", {
                    attrs: { flat: "", type: "spinner--center" }
                  })
                : _vm._e(),
              _vm.submissionData
                ? [
                    _c("v-layout", { attrs: { wrap: "" } }, [
                      _c(
                        "div",
                        { staticClass: "flex formContainer" },
                        [
                          _c(
                            "v-layout",
                            { staticClass: "formPanel", attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _vm.submissionData.status ===
                                        "rejected" &&
                                      _vm.submissionData.reject_notes
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text__warning"
                                                },
                                                [
                                                  _vm._v(
                                                    "Opmerkingen betreft afwijzing"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "value disabled"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.submissionData
                                                          .reject_notes
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.submissionData.user
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "label" },
                                                [_vm._v("Naam")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "value disabled"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.submissionData.user
                                                          .name
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c("div", { staticClass: "label" }, [
                                          _vm._v("Niveau")
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "value disabled" },
                                          [
                                            _c("v-select", {
                                              staticClass: "pa-0 ma-0",
                                              attrs: {
                                                disabled:
                                                  _vm.readOnly ||
                                                  _vm.user.is_opnemer,
                                                "prepend-icon": "poll",
                                                items: _vm.userLevels,
                                                "item-text": "name",
                                                "item-value": "value",
                                                placeholder: "Niveau",
                                                "persistent-hint": ""
                                              },
                                              model: {
                                                value: _vm.submissionData.level,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.submissionData,
                                                    "level",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "submissionData.level"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]),
                                      !_vm.user.is_opnemer
                                        ? [
                                            _vm.isJunior
                                              ? _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    !_vm.readOnly
                                                      ? _c("v-text-field", {
                                                          attrs: {
                                                            label:
                                                              "Verklaring van deskundigheid",
                                                            "prepend-icon":
                                                              "attach_file"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.onClickFileSelectDeclarationOfExpertise
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .fileDeclarationOfExpertise
                                                                .name,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.fileDeclarationOfExpertise,
                                                                "name",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fileDeclarationOfExpertise.name"
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    !_vm.readOnly
                                                      ? _c("input", {
                                                          ref:
                                                            "fileInputDeclarationOfExpertise",
                                                          staticStyle: {
                                                            display: "none"
                                                          },
                                                          attrs: {
                                                            type: "file",
                                                            accept: "*/*"
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.onFileSelectedDeclarationOfExpertise
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm.submissionData
                                                      .declaration_of_expertise &&
                                                    _vm.submissionData
                                                      .declaration_of_expertise
                                                      .original
                                                      ? [
                                                          _vm.readOnly
                                                            ? _c(
                                                                "MiButton",
                                                                {
                                                                  attrs: {
                                                                    icon:
                                                                      "cloud_download",
                                                                    round:
                                                                      "true",
                                                                    outline:
                                                                      "true",
                                                                    background:
                                                                      "true"
                                                                  },
                                                                  nativeOn: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openFileUrl(
                                                                        _vm
                                                                          .submissionData
                                                                          .declaration_of_expertise
                                                                          .original
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Verklaring van deskundigheid "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      : _vm._e()
                                                  ],
                                                  2
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c("div", { staticClass: "label" }, [
                                          _vm._v("Competenties")
                                        ]),
                                        !_vm.readOnly
                                          ? _c(
                                              "div",
                                              { staticClass: "value disabled" },
                                              [
                                                _c("v-autocomplete", {
                                                  staticClass: "with-border",
                                                  attrs: {
                                                    multiple: "",
                                                    "prepend-icon":
                                                      "psychology",
                                                    items: _vm.skills,
                                                    "item-text": "name",
                                                    "item-value": "id",
                                                    "return-object": "",
                                                    placeholder: "Competenties",
                                                    "persistent-hint": ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.submissionData.skills,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.submissionData,
                                                        "skills",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "submissionData.skills"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "value disabled" },
                                              _vm._l(
                                                _vm.submissionData.skills,
                                                function(skill, index) {
                                                  return _c(
                                                    "v-chip",
                                                    {
                                                      key: "skill-" + index,
                                                      style:
                                                        "background-color: " +
                                                        skill.color +
                                                        " !important"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(skill.name)
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    !_vm.readOnly
                                      ? _c("v-text-field", {
                                          attrs: {
                                            label: "Verklaring omtrent gedrag",
                                            "prepend-icon": "attach_file"
                                          },
                                          on: {
                                            click:
                                              _vm.onClickFileSelectDeclarationOfGoodBehaviour
                                          },
                                          model: {
                                            value:
                                              _vm.fileDeclarationOfGoodBehaviour
                                                .name,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.fileDeclarationOfGoodBehaviour,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "fileDeclarationOfGoodBehaviour.name"
                                          }
                                        })
                                      : _vm._e(),
                                    !_vm.readOnly
                                      ? _c("input", {
                                          ref:
                                            "fileInputDeclarationOfGoodBehaviour",
                                          staticStyle: { display: "none" },
                                          attrs: {
                                            type: "file",
                                            accept: "*/*"
                                          },
                                          on: {
                                            change:
                                              _vm.onFileSelectedDeclarationOfGoodBehaviour
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.submissionData
                                      .declaration_of_good_behaviour &&
                                    _vm.submissionData
                                      .declaration_of_good_behaviour.original
                                      ? [
                                          _vm.readOnly
                                            ? _c(
                                                "MiButton",
                                                {
                                                  attrs: {
                                                    icon: "cloud_download",
                                                    round: "true",
                                                    outline: "true",
                                                    background: "true"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.openFileUrl(
                                                        _vm.submissionData
                                                          .declaration_of_good_behaviour
                                                          .original
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Verklaring omtrent gedrag "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    !_vm.readOnly
                                      ? _c("v-text-field", {
                                          attrs: {
                                            label: "Assessment",
                                            "prepend-icon": "attach_file"
                                          },
                                          on: {
                                            click:
                                              _vm.onClickFileSelectAssessment
                                          },
                                          model: {
                                            value: _vm.fileAssessment.name,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.fileAssessment,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "fileAssessment.name"
                                          }
                                        })
                                      : _vm._e(),
                                    !_vm.readOnly
                                      ? _c("input", {
                                          ref: "fileInputAssessment",
                                          staticStyle: { display: "none" },
                                          attrs: {
                                            type: "file",
                                            accept: "*/*"
                                          },
                                          on: {
                                            change: _vm.onFileSelectedAssessment
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.submissionData.assessment &&
                                    _vm.submissionData.assessment.original
                                      ? [
                                          _vm.readOnly
                                            ? _c(
                                                "MiButton",
                                                {
                                                  attrs: {
                                                    icon: "cloud_download",
                                                    round: "true",
                                                    outline: "true",
                                                    background: "true"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.openFileUrl(
                                                        _vm.submissionData
                                                          .assessment.original
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Assessment ")]
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              _vm.submissionData.level !==
                                _vm.Level.PROCESEXPERT &&
                              _vm.submissionData.level !==
                                _vm.Level.SCHADEOPNEMER
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          disabled: _vm.readOnly,
                                          label:
                                            "Wat is de reden van de aanvraag"
                                        },
                                        model: {
                                          value:
                                            _vm.submissionData.submission_notes,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.submissionData,
                                              "submission_notes",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "submissionData.submission_notes"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.submissionData.level !==
                                _vm.Level.PROCESEXPERT &&
                              _vm.submissionData.level !==
                                _vm.Level.SCHADEOPNEMER
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("DateFilter", {
                                        attrs: {
                                          readOnly: _vm.readOnly,
                                          label:
                                            "Werkzaam als schade-expert sinds"
                                        },
                                        model: {
                                          value: _vm.startedWorkingAt,
                                          callback: function($$v) {
                                            _vm.startedWorkingAt = $$v
                                          },
                                          expression: "startedWorkingAt"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pt-0",
                                    attrs: {
                                      disabled: _vm.readOnly,
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.postcode
                                      ],
                                      label: "Postcode",
                                      placeholder: "vb: 1012CW"
                                    },
                                    model: {
                                      value: _vm.submissionData.postcode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.submissionData,
                                          "postcode",
                                          $$v
                                        )
                                      },
                                      expression: "submissionData.postcode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            2
                          ),
                          _vm.canCreateSubmissionItem
                            ? [
                                _c("SubmissionExperienceList", {
                                  attrs: {
                                    readOnly: _vm.readOnly,
                                    experienceSubmission: _vm.submission,
                                    title: "Opleiding(en)",
                                    type: _vm.ExperienceType.SCHOOL,
                                    singular: "Opleiding",
                                    noResultsText:
                                      "Er zijn nog geen opleiding(en) toegevoegd"
                                  },
                                  on: {
                                    addSubmissionExperiences:
                                      _vm.addSubmissionExperiences,
                                    updateSubmissionExperiences:
                                      _vm.updateSubmissionExperiences,
                                    deleteSubmissionExperiences:
                                      _vm.deleteSubmissionExperiences
                                  }
                                }),
                                _c("SubmissionExperienceList", {
                                  attrs: {
                                    readOnly: _vm.readOnly,
                                    experienceSubmission: _vm.submission,
                                    title: "Werkervaring",
                                    type: _vm.ExperienceType.WORK,
                                    singular: "Ervaring",
                                    noResultsText:
                                      "Er is nog geen werkervaring toegevoegd"
                                  },
                                  on: {
                                    addSubmissionExperiences:
                                      _vm.addSubmissionExperiences,
                                    updateSubmissionExperiences:
                                      _vm.updateSubmissionExperiences,
                                    deleteSubmissionExperiences:
                                      _vm.deleteSubmissionExperiences
                                  }
                                }),
                                _c("SubmissionExperienceList", {
                                  attrs: {
                                    readOnly: _vm.readOnly,
                                    experienceSubmission: _vm.submission,
                                    title: "Nevenfuncties",
                                    type: _vm.ExperienceType.SIDE_ACTIVITY,
                                    singular: "Nevenfunctie",
                                    noResultsText:
                                      "Er zijn nog geen Nevenfunctie(s) toegevoegd"
                                  },
                                  on: {
                                    addSubmissionExperiences:
                                      _vm.addSubmissionExperiences,
                                    updateSubmissionExperiences:
                                      _vm.updateSubmissionExperiences,
                                    deleteSubmissionExperiences:
                                      _vm.deleteSubmissionExperiences
                                  }
                                }),
                                _c("SubmissionExperienceList", {
                                  attrs: {
                                    readOnly: _vm.readOnly,
                                    experienceSubmission: _vm.submission,
                                    title: "Bijzondere expertises",
                                    type: _vm.ExperienceType.SPECIAL_EXPERTISE,
                                    singular: "Bijzondere expertise",
                                    noResultsText:
                                      "Er is nog geen Bijzondere expertises toegevoegd"
                                  },
                                  on: {
                                    addSubmissionExperiences:
                                      _vm.addSubmissionExperiences,
                                    updateSubmissionExperiences:
                                      _vm.updateSubmissionExperiences,
                                    deleteSubmissionExperiences:
                                      _vm.deleteSubmissionExperiences
                                  }
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]),
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "mt-3": "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                type: "number",
                                disabled: _vm.readOnly,
                                label: "Beschikbaarheid in de FTE",
                                placeholder: "0.0, 0.1, 0.2, 0.3, etc t/m 1.0",
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.submissionData.available_fte,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.submissionData,
                                    "available_fte",
                                    $$v
                                  )
                                },
                                expression: "submissionData.available_fte"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.isCheckingExpertStatus && _vm.isSelf
                          ? [
                              _vm.submissionData
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label:
                                            "ik ga akkoord met de verklaring van onafhankelijkheid",
                                          "hide-details": "",
                                          color: "#a09b1b"
                                        },
                                        model: {
                                          value:
                                            _vm.submissionData
                                              .agreed_to_declaration_of_independence,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.submissionData,
                                              "agreed_to_declaration_of_independence",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "submissionData.agreed_to_declaration_of_independence"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        return _vm.openReadMoreDialog(
                                          "verklaring_van_onafhankelijkheid"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("u", [
                                      _vm._v(
                                        "Bekijk de verklaring van onafhankelijkheid"
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          !_vm.readOnly
            ? _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-end": "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          [
                            _c(
                              "span",
                              {
                                staticClass: "mr-3 fadedText link",
                                on: { click: _vm.close }
                              },
                              [_vm._v("Annuleren")]
                            ),
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  disabled: _vm.isDisabled || _vm.isLoading,
                                  color: "primary",
                                  small: "true"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.submit($event)
                                  }
                                }
                              },
                              [_vm._v(" Aanvragen ")]
                            )
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isCheckingExpertStatus && _vm.isSelf
            ? _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-end": "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          [
                            _c(
                              "span",
                              {
                                staticClass: "mr-3 fadedText link",
                                on: { click: _vm.close }
                              },
                              [_vm._v("Annuleren")]
                            ),
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  disabled:
                                    (_vm.submissionData &&
                                      !_vm.submissionData
                                        .agreed_to_declaration_of_independence) ||
                                    _vm.isLoading,
                                  color: "primary",
                                  small: "true"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.approve($event)
                                  }
                                }
                              },
                              [_vm._v(" Goedkeuren ")]
                            )
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }