import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { User } from '@/models/User';
import { ExperienceSubmissionStatus, statusLabels } from '@/models/ExperienceSubmission';

@Component<ExpertSubmissionStatusFilter>({})
export default class ExpertSubmissionStatusFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop()
  protected placeholder!: string;

  @Prop()
  protected filters!: any;

  protected defaultFilters: any = {};

  @Prop()
  protected label!: string;

  @Prop({ default: 'with-full-border' })
  protected cssClass!: string;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  protected experts: User[] = [];

  protected selectedStatuses: User[] = [];

  protected statuses: StatusItem[] = [
    { key: ExperienceSubmissionStatus.PENDING, name: statusLabels[ExperienceSubmissionStatus.PENDING] },
    { key: ExperienceSubmissionStatus.REJECTED, name: statusLabels[ExperienceSubmissionStatus.REJECTED] },
    { key: ExperienceSubmissionStatus.VERIFIED, name: statusLabels[ExperienceSubmissionStatus.VERIFIED] },
    { key: ExperienceSubmissionStatus.CHECKING_EXPERT, name: statusLabels[ExperienceSubmissionStatus.CHECKING_EXPERT] },
  ]

  protected handleChange() {
    this.$emit('input', this.selectedStatuses);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedStatuses = [];
    } else {
      this.selectedStatuses = cloneDeep(this.value);
    }
  }

  public async mounted() {
    this.defaultFilters = { ...this.filters, ...this.defaultFilters };
  }
}

interface StatusItem {
  key?: ExperienceSubmissionStatus;
  name?: string;
}
