var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    { on: { close: _vm.close } },
    [
      _c("template", { slot: "header" }, [_vm._v("Aanvraag afkeuren")]),
      _vm.submission
        ? _c(
            "template",
            { slot: "content" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _vm.submission.user
                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "label" }, [_vm._v("Naam")]),
                        _c("div", { staticClass: "value disabled" }, [
                          _vm._v(" " + _vm._s(_vm.submission.user.name) + " ")
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        attrs: { label: "Opmerking" },
                        model: {
                          value: _vm.notes,
                          callback: function($$v) {
                            _vm.notes = $$v
                          },
                          expression: "notes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "actions" },
        [
          _c(
            "v-layout",
            { attrs: { "justify-end": "", "align-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  [
                    _c(
                      "span",
                      {
                        staticClass: "fadedText link mr-3",
                        on: { click: _vm.close }
                      },
                      [_vm._v("Annuleren")]
                    ),
                    _c(
                      "MiButton",
                      {
                        attrs: { color: "warning", small: "true" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.submit($event)
                          }
                        }
                      },
                      [_vm._v("afkeuren")]
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }