import { Component, Vue, Prop } from 'vue-property-decorator';
import { Experience, ExperienceType } from '@/models/Experience';
import { ExperienceSubmission } from '@/models/ExperienceSubmission';
import EnrollmentListDialog from '@/views/Users/ExpertRegister/EnrollmentListDialog/EnrollmentListDialog.vue';
import * as faker from 'faker';
import { ExperienceSubmissionItem } from '@/models/ExperienceSubmissionItem';
import { cloneDeep } from 'lodash';

@Component<SubmissionExperienceList>({
  components: {
    EnrollmentListDialog,
  },
})
export default class SubmissionExperienceList extends Vue {
  @Prop()
  protected experienceSubmission!: ExperienceSubmission;

  @Prop({ default: false })
  protected readOnly!: string;

  @Prop()
  protected title!: string;

  @Prop()
  protected singular!: string;

  @Prop()
  protected noResultsText!: string;

  @Prop({ })
  protected type!: ExperienceType;

  protected experiences: ExperienceSubmissionItem[] = [];

  protected showExperienceDialog = false;

  protected selectedIndex = 0;

  protected editMode = false;

  protected selectedExperience: Experience | null = null;

  protected async mounted() {
    if (this.experienceSubmission) {
      this.experienceSubmission.items?.forEach((experience) => {
        if (experience.type === this.type) {
          this.experiences.push(this.parseExperience(experience));
        }
      });
    }
  }

  protected async onExperienceIsVerifiedInput(experience: ExperienceSubmissionItem): Promise<void> {
    this.updateExperience(experience);
  }

  protected parseExperience(experience: ExperienceSubmissionItem): ExperienceSubmissionItem {
    return {
      id: experience.id,
      type: experience.type,
      completed: experience.completed,
      title: experience.title,
      experience: experience.experience,
      description: experience.description,
      starts_at: experience.starts_at,
      ends_at: experience.ends_at,
      declaration_of_education: experience.declaration_of_education,
      is_verified: experience.is_verified,
    } as ExperienceSubmissionItem;
  }

  protected createExperience(payload: ExperienceSubmissionItem) {
    payload.id = faker.random.uuid();
    this.experiences.push(payload);
    this.showExperienceDialog = false;
    this.$emit('addSubmissionExperiences', payload);
  }

  protected updateExperience(payload: ExperienceSubmissionItem) {
    this.experiences[this.selectedIndex] = payload;
    this.selectedExperience = null;
    this.showExperienceDialog = false;
    this.$emit('updateSubmissionExperiences', payload);
  }

  protected async deleteExperience(index: number) {
    this.$emit('deleteSubmissionExperiences', this.experiences[index]);
    this.experiences.splice(index, 1);
  }

  protected editExperience(experience: Experience, index: number): void {
    this.selectedExperience = experience;
    this.editMode = true;
    this.selectedIndex = index;
    this.showExperienceDialog = true;
  }

  protected addExperience() {
    this.selectedExperience = null;
    this.editMode = false;
    this.showExperienceDialog = true;
  }

  protected get isSchool() {
    return this.type === ExperienceType.SCHOOL;
  }
}
