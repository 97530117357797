import { Component, Vue, Prop } from 'vue-property-decorator';
import { ExperienceSubmission } from '@/models/ExperienceSubmission';

@Component<RejectSubmissionDialog>({})
export default class RejectSubmissionDialog extends Vue {
  @Prop()
  protected organization!: string;

  @Prop()
  protected submission!: ExperienceSubmission;

  protected notes = '';

  protected mounted() {

  }

  protected close() {
    this.$emit('input', false);
  }

  protected async submit() {
    await this.submission.dmz(this.organization)
      .put({
        status: 'rejected',
        reject_notes: this.notes,
      });

    this.close();
    this.$emit('submitted');
  }
}
