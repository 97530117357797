var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "formPanel enrollmentList",
      attrs: { "align-content-start": "" }
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { "justify-space-between": "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "panelHeaderContainer",
                  attrs: { shrink: "", "mb-2": "", "pa-0": "" }
                },
                [
                  _c("div", { staticClass: "panelHeader" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                ]
              ),
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  !_vm.readOnly
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "addEntry",
                          nativeOn: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.addExperience()
                            }
                          }
                        },
                        [_vm._v("mdi-plus")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "align-center": "" } },
            [
              _vm.experiences.length > 0
                ? _vm._l(_vm.experiences, function(experience, index) {
                    return _c(
                      "v-flex",
                      { key: index, staticClass: "entry", attrs: { xs12: "" } },
                      [
                        _vm.isSchool
                          ? _c("v-icon", { staticClass: "entry-icon" }, [
                              _vm._v("mdi-school")
                            ])
                          : _c("v-icon", { staticClass: "entry-icon" }, [
                              _vm._v("mdi-briefcase")
                            ]),
                        _c("div", { staticClass: "entry__info" }, [
                          _c("span", [
                            _c("span", { staticClass: "entry__title" }, [
                              _vm._v(" " + _vm._s(experience.title) + " ")
                            ]),
                            experience.experience
                              ? _c("span", { staticClass: "entry__subtitle" }, [
                                  _vm._v(
                                    " | " + _vm._s(experience.experience) + " "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm.isSchool
                            ? _c("span", [
                                _vm._v(
                                  "Afgerond: " +
                                    _vm._s(experience.completed ? "Ja" : "Nee")
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " + _vm._s(experience.description) + " "
                                )
                              ]),
                          _c("div", { staticClass: "entry__period" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(experience.starts_at)
                                ) +
                                " - " +
                                _vm._s(
                                  _vm._f("dateFormat")(experience.ends_at)
                                ) +
                                " "
                            )
                          ])
                        ]),
                        !_vm.readOnly
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "edit-icon",
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.editExperience(experience, index)
                                  }
                                }
                              },
                              [_vm._v(" mdi-pencil ")]
                            )
                          : _vm._e(),
                        !_vm.readOnly
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "cursor-pointer delete-icon",
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.deleteExperience(index)
                                  }
                                }
                              },
                              [_vm._v(" mdi-delete ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  })
                : [
                    _c("div", { staticClass: "fadedText italicText" }, [
                      _vm._v(" " + _vm._s(_vm.noResultsText) + " ")
                    ])
                  ]
            ],
            2
          )
        ],
        1
      ),
      _vm.showExperienceDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.showExperienceDialog,
                callback: function($$v) {
                  _vm.showExperienceDialog = $$v
                },
                expression: "showExperienceDialog"
              }
            },
            [
              _c("EnrollmentListDialog", {
                attrs: {
                  experience: _vm.selectedExperience,
                  singular: _vm.singular,
                  type: _vm.type
                },
                on: {
                  createExperience: _vm.createExperience,
                  updateExperience: _vm.updateExperience
                },
                model: {
                  value: _vm.showExperienceDialog,
                  callback: function($$v) {
                    _vm.showExperienceDialog = $$v
                  },
                  expression: "showExperienceDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }