import { cloneDeep } from 'lodash';

import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { DateFormatter } from '@/support/DateFormatter';
import { DateTime } from 'luxon';
import { Experience, ExperienceType } from '@/models/Experience';
import { User } from '@/models/User';
import { FileObject } from '@/views/ExpertRegisterSubmissions/CreateSubmissionDialog/CreateSubmissionDialog';

@Component<EnrollmentListDialog>({

})
export default class EnrollmentListDialog extends Vue {
  @Prop({ default: null })
  protected experience!: Experience;

  protected experienceData: any = {};

  @Prop()
  protected user!: User;

  @Prop()
  protected organization!: string;

  @Prop()
  protected singular!: string;

  @Prop()
  protected type!: ExperienceType;

  protected file: FileObject = {
    name: '',
    url: '',
    object: null,
  };

  protected dateFrom: DateFormatter | null = null;

  protected dateTill: DateFormatter | null = null;

  protected valid = false;

  protected isLoading = true;

  private organizationRules: any = [
    (v: string) => !! v || 'Organisatie is verplicht',
  ];

  private activitiesRules: any = [
    (v: string) => !! v || 'Werkzaamheden zijn verplicht',
  ];

  protected mounted(): void {
    this.dateFrom = new DateFormatter();
    this.dateTill = new DateFormatter();

    this.experienceData = this.experience ? cloneDeep(this.experience) : { completed: false };

    if (this.experienceData) {
      this.fillDateFrom();
      this.fillDateTill();
    }

    this.isLoading = false;
  }

  protected close(): void {
    this.$emit('input', false);
  }

  protected save(): void {
    if (this.experience) {
      this.experienceData.starts_at = this.dateFrom?.selectedDate;
      this.experienceData.ends_at = this.dateTill?.selectedDate;
      this.$emit('updateExperience', this.experienceData);
      return;
    }

    this.$emit('createExperience', this.createPayload);
  }

  protected get createPayload() {
    const payload: {[key: string]: string | boolean} = {
      user: this?.user?.uuid || '',
      type: this.type,
      title: this.experienceData?.title || '',
      description: this.experienceData?.description || '',
      completed: this.experienceData?.completed,
      experience: this.experienceData?.experience || '',
      starts_at: this.dateFrom?.selectedDate || '',
      ends_at: this.dateTill?.selectedDate || '',
    };

    return payload;
  }

  protected fillDateFrom() {
    if (! this.experience || ! this.dateFrom) {
      return;
    }
    if (! this.experience.starts_at || this.experience.starts_at === 'Invalid DateTime') {
      return;
    }

    this.dateFrom.selectedDate = DateTime.fromSQL(`${this.experience.starts_at}`).toFormat('yyyy-MM-dd');
  }

  protected fillDateTill() {
    if (! this.experience || ! this.dateTill) {
      return;
    }

    if (! this.experience.ends_at || this.experience.ends_at === 'Invalid DateTime') {
      return;
    }

    this.dateTill.selectedDate = DateTime.fromSQL(`${this.experience.ends_at}`).toFormat('yyyy-MM-dd');
  }

  protected get typeLabel() {
    if (this.type === ExperienceType.SCHOOL) {
      return 'Opleiding';
    }

    return 'Functie';
  }

  protected get isSchool() {
    return this.type === ExperienceType.SCHOOL;
  }

  protected get isWork() {
    return this.type === ExperienceType.WORK;
  }
}
